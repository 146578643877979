<template>
  <div>
    <button class="spin-btn" @click="spin">SPIN</button>
    <a-scene background="color: red" physics="debug: true">
      <a-entity rotation="90 0 0" position="0 1.5 -4">
        <a-entity rotation="0 0 0" ref="spinner">
          <a-cylinder
            v-for="(color, i) in colors"
            :key="i"
            :color="color"
            position="0 1 0"
            radius="2"
            :rotation="`0 ${(i * 360) / colors.length} 0`"
            :theta-length="360 / colors.length"
          >
            <a-entity :rotation="`0 -65 0`">
              <a-text
                rotation="-90 0 0"
                position="1 0.5 0"
                align="center"
                anchor="center"
                baseline="center"
                :value="color"
              ></a-text>
            </a-entity>
          </a-cylinder>
        </a-entity>
      </a-entity>

      <a-entity oculus-touch-controls="hand: left"></a-entity>
      <a-entity
        oculus-touch-controls="hand: right"
        @triggerup="(color = 'white'), spin()"
        @triggerdown="color = 'blue'"
        class="wheel"
      >
        <a-sphere
          radius=0.1
          :color="color"
          id="hand"
          static-body
          position="0 0 -0.2"
        ></a-sphere>

      </a-entity>

      <a-entity
        camera
        look-controls
        wasd-controls
        position="0 1.6 1"
      ></a-entity>

      <a-sky color="#ECECEC"></a-sky>
    </a-scene>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {
      color: "white",
      colors: ["purple", "red", "green", "orange", "purple", "red", "green", "orange"],
      anim: false,
    };
  },
  methods: {
    spin() {
      // this.$refs.spinner.emit("spin");
      this.$refs.spinner.setAttribute(
        "animation",
        `autoplay: true; property: rotation; to: 0 ${
          360 + Math.random() * 1000
        } 0;  dur: 2000; easing: easeInOutSine`
      );
      // this.anim = true
    },
    pause() {
      console.log("sdfsdf");
      this.$refs.spinner.emit("pause");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.spin-btn {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 50px;
  width: 150px;
  z-index: 5;
  color: white;
  background: chartreuse;
}
</style>
